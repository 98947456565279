@import 'includes.scss';

#cookiescript_badge {
    box-shadow: none !important;
    background-color: $colorWhite !important;
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important;
    left: 8px !important;
    border: 1px solid $colorBlack;
    border-bottom: none !important;
}

#cookiescript_badgetext {
    color: $colorBlack !important;
    text-transform: none !important;
    font-family: $fontMedium !important;
    font-weight: normal !important;
    font-size: 1.3rem !important;
}

#cookiescript_injected,
#cookiescript_injected_fsd {
    padding: 24px 16px 16px 16px !important;
    box-shadow: none !important;
    width: calc(100% - 32px) !important;
    font-family: $fontLight !important;
    background-color: $colorWhite !important;

    @include media(m) {
        padding: 40px 32px 32px 32px !important;
    }

    #cookiescript_injected_wrapper {}

    #cookiescript_header {
        @extend %h4;
        margin: 0 0 16px 0;
        font-weight: normal;
        color: $colorBlack;
        font-family: $fontLight !important;
    }

    .mdc-checkbox__background {
        border: 1px solid transparent !important;
        border-radius: 0 !important;
    }

    .mdc-checkbox__native-control:enabled~.mdc-checkbox__background {
        border-color: $colorBlack !important;
    }

    .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
    .mdc-checkbox__native-control[disabled]:checked~.mdc-checkbox__background {
        background-color: $colorRed50 !important;
        border-color: transparent !important;
    }

    .mdc-checkbox__native-control:enabled~.mdc-checkbox__background .mdc-checkbox__checkmark,
    .mdc-checkbox__native-control:disabled~.mdc-checkbox__background .mdc-checkbox__checkmark {
        color: $colorWhite !important;
        scale: 0.85;
    }

    #cookiescript_description {
        margin: 0 0 16px 0;
    }

    #cookiescript_description,
    #cookiescript_description span {
        font-family: $fontLight;
        color: $colorBlack;
        font-weight: normal;

        @extend %p;
    }

    #cookiescript_accept,
    #cookiescript_reject,
    #cookiescript_save {
        border-radius: 4px !important;
        font-weight: normal !important;
        letter-spacing: 0.35px !important;
        text-align: center !important;
        color: $colorBlack !important;
        line-height: 1 !important;
        cursor: pointer !important;
        font-size: 1.6rem;
        font-family: $fontMedium;
        text-transform: none;
        padding: 14px 24px !important;

        @include media(m) {
            padding: 16px 32px !important;
            font-size: 1.8rem;
        }
    }

    #cookiescript_reject {
        border: 1px solid $colorBlack;
    }

    #cookiescript_accept,
    #cookiescript_save {
        background-color: $colorRed50 !important;
        color: $colorWhite !important;

        @media (hover: hover) {
            &:hover {
                background-color: $colorRed70 !important;
            }
        }
    }

    span.cookiescript_checkbox_text {
        color: $colorBlack !important;
        text-transform: none;
        font-size: 1.6rem;
    }

    #cookiescript_accept {
        min-width: 150px;
    }

    #cookiescript_reject {
        background-color: #ffffff !important;
    }

    #cookiescript_readmore {
        color: $colorRed50;
        display: block;
        text-decoration: underline;
        font-family: $fontLight;
        font-weight: normal;

        @extend %p;
    }

    a {
        text-underline-offset: 2px;
        text-decoration: underline !important;
    }

    .cookiescript_fsd_tabs_content {
        background-color: $colorRed50;
        mask-image: none !important;
    }

    .cookiescript_fsd_tabs>.cookiescript_active {
        border-top: 2px solid $colorBlack;
        background-color: $colorRed50 !important;
    }

    #cookiescript_aboutwrap {
        color: $colorBlack;
        padding: 0;
        margin-top: 8px;
    }

    #cookiescript_manage {
        display: inline-block;
        margin-top: 16px;
        font-size: 1.2rem;
        color: $colorBlack !important;
        text-decoration: underline;
        text-underline-offset: 3px;
        text-transform: none;
    }

    #cookiescript_manageicon {
        display: none !important;
    }

    #cookiescript_checkboxs {
        width: 100%;
        display: block;

        @include media(m) {
            width: auto;
            display: flex;
        }
    }

    .cookiescript_vendor_name a,
    #cookiescript_categories .cookiescript_active,
    #cookiescript_iab_type .cookiescript_active,
    .cookiescript_fullreport td:nth-child(1)
    .cookiescript_fullreport th {
        font-weight: normal !important;
        font-family: $fontMedium !important;
    }

    #cookiescript_maintabs > div {
        color: $colorBlack;
        background-color: $colorGray10;
        font-family: $fontMedium;
        text-transform: none;
        transition: none;
    }

    #cookiescript_maintabs .cookiescript_active {
        color: $colorWhite;
        background-color: $colorRed50;
        font-family: $fontMedium;
        text-transform: none;
    }

    #cookiescript_categories > div {
        font-weight: normal !important;
        color: $colorBlack !important;
        background-color: $colorGray10 !important;
        font-family: $fontMedium;
    }

    #cookiescript_categories > div.cookiescript_active {
        background-color: $colorRed50 !important;
        color: $colorWhite !important;
    }

    .cookiescript_category_description,
    .cookiescript_fullreport td {
        font-size: 1.2rem;
        color: $colorBlack;
        font-weight: normal;
    }

    .cookiescript_fullreport th {
        font-family: $fontMedium !important;
        font-weight: normal;
        background-color: $colorGray10;
        color: $colorBlack;
    }

    .cookiescript_fullreport td:nth-child(1) {
        
        font-family: $fontMedium !important;
    }
}