html,
body {
    padding: 0;
    margin: 0;
    font-family: $fontLight;
    -webkit-font-smoothing: antialiased;
    font-size: 1.6rem;
    color: $colorBlack;
}

html {
    font-size: 62.5%;
}

.responsive-object {
    position: relative;
    padding-bottom: 56.25%;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.sr-only {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important;
    height: 1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important;
    text-transform: none;
}

#nprogress .peg {
    box-shadow: none !important;
}

a {
    color: $colorBlack;
    text-decoration: none;
}

.headroom.headroom--unfixed {
    transform: none !important;
}

* {
    box-sizing: border-box;
}